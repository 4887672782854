import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// Styles
import "./sass/main.scss";
import {
	FaSignOutAlt,
} from "react-icons/fa";

// Images
//import logo from "./images/logo.svg";
// Custom Components
//import About from "./components/pages/About.js";
import BingoGame from "./components/BingoGame.js";
import CardGenerator from "./components/pages/CardGenerator.js";
import CashierLogin from "./components/CashierLogin.js";
//import Donate from "./components/pages/Donate.js";
//import Help from "./components/pages/Help.js";
//import Privacy from "./components/pages/Privacy.js";
//import ReleaseNotes from "./components/pages/ReleaseNotes.js";
//import Terms from "./components/pages/Terms.js";
//import Patterns from "./components/pages/Patterns.js";
const sign_out = () => {
	sessionStorage.removeItem("cashier_name");
	sessionStorage.removeItem("shop_number");
	// console.log(sessionStorage.getItem("cashier_name"));
	//console.log("signed out");
	window.location.replace("/cashier");
};
const routing = (
	<Router>
		{/*	<header>
			<div className="container row align-center">
				<div className="col shrink">
					<Link to="/">
						<img src={logo} alt="Let's Play Bingo!" className="logo" />
					</Link>
				</div>
				<div className="col grow padding-md no-text-wrap text-right">
					<ul className="menu">
						<li>
							<Link to="/">Play</Link>
						</li>
						<li>
							<Link to="/generator">Cards</Link>
						</li>
						{/*<li>
								<Link to="/help">Help</Link>
						</li>
						<li>
							<Link to="/about">About / Donate</Link>
						</li>
						<li>
							<a href="https://letsplaybingo.io" target="_blank" rel="noreferrer">
								Latest Edition
							</a>
                        </li>
					</ul>
				</div>*/}
		{/*<div className="col shrink text-right margin-left-lg">
					<div id="google_translate_element"></div>
				</div>
			</div>
		{/*</header>*/}

		{/*<Route exact path="/bingogame" component={BingoGame} />*/}
		<Route path="/bingogame" component={sessionStorage.getItem('cashier_name') != null ? BingoGame : CashierLogin} />
		<Route path="/generator" component={sessionStorage.getItem('cashier_name') != null ? CardGenerator : CashierLogin} />
		{/*<Route path="/generator" component={CardGenerator} />*/}
		<Route path="/cashier" component={CashierLogin} />
		{/*	<Route path="/about" component={About} />
		<Route path="/donate" component={Donate} />		
		<Route path="/terms" component={Terms} />
		<Route path="/patterns" component={Patterns} />
		<Route path="/privacy" component={Privacy} />
		<Route path="/releases" component={ReleaseNotes} />
		<Route path="/help" component={Help} />*/}
		{/*<div className="col shrink">
			<Link to="/">
				<img src={logo} alt="Let's Play Bingo!" className="logo" />
			</Link>
		</div>*/}

		<footer>
			<div className="container row three-cols align-center">
				<div className="col">For entertainment purposes only.</div>
				<div className="col text-center">
					&copy; {new Date().getFullYear()} &nbsp;&nbsp;&nbsp; 0987308245
				</div>
				{/*<div className="col text-right">
					<Link to="/releases">Release Notes</Link> | <Link to="/terms">Terms of Use</Link> |{" "}
					<Link to="/privacy">Cookies &amp; Privacy Policy</Link>
				</div>*/}

				<ul className="menu">
					<li>
						<Link to="/bingogame">Play</Link>
					</li>
					<li>
						<Link to="/generator">Cards</Link>
					</li>
					<li>


						{/* <i class="fa fa-sign-out" aria-hidden="true"></i> */}
						<Link onClick={() => sign_out()}>
							<FaSignOutAlt /> Sign Out
						</Link>

					</li>
					{/*<li>
								<Link to="/help">Help</Link>
						</li>
						<li>
							<Link to="/about">About / Donate</Link>
						</li>
						<li>
							<a href="https://letsplaybingo.io" target="_blank" rel="noreferrer">
								Latest Edition
							</a>
                        </li>*/}
				</ul>
			</div>


		</footer>
	</Router>
);
ReactDOM.render(routing, document.getElementById("root"));
